.logonum{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 0 30px;
    background-color:rgb(255, 255, 255);
    /* 
    backdrop-filter: blur(10px); */
    /* box-shadow: 0 5px #ff0000; */
    width: 100%;
    height: 50px;
    /* border-radius: 7px; */
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 99;
    display: none; 

}

.psbox i{
    color: rgb(24,141,201);
    color: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
  
}
.psbox a{
    color: black;
    text-decoration: none;
    font-weight: 500;
}

.logonum img{
   
    width: 7rem;
    height: 3rem;
}
.mainlogo img{
    margin-top: 3px;
    width: 2rem;
    height: 3rem;
}
.pno {
    font-weight: 600;
}
.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 0 30px;


    background-color:#8989897a;
    /* background-color: rgba(255, 255, 255, 0.301); */
    backdrop-filter: blur(10px);
    /* box-shadow: 0 5px #ff0000; */

    /* background: linear-gradient(90deg, rgba(255, 81, 46, 1) 0%, rgba(240, 126, 24, 1) 100%); */
    color: white;
    width: 95%;
    height: 50px;
    border-radius: 7px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 99;
    
}
/* .logo{
    color: white;
    justify-self: start;
    cursor: pointer;
} */
.logo img{
    margin-top: 9px;
    width: 6.8rem;
    height: 2.6rem;
}
.fa-react{
    margin-left: 0.2rem;
    font-size: 1rem;
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 1px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.nav-links{
    text-decoration: none;
    color: rgb(24, 24, 24);
    /* color: white; */
    padding: 0.4rem 0.6rem;
    white-space: nowrap;
   
    
}
.nav-links i{
padding-right: 10px;
}
.nav-links:hover{
    background-color: rgba(197, 197, 197, 0.534);
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
   
}
.fa-bars,
.fa-times{
    color: white;

}
.menu-icons{
    display: none;
}
.nav-links-mobile{
    padding: 0.4rem 1rem;
    white-space: nowrap;
    background: rgb(24,141,201);
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
    /* background-color: rgb(71, 166, 255); */
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    color: black;
    font-weight: 400;
    color: white;
   
}








@media screen and (max-width:1154px)  {
    .logonum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        padding:15px 20px;
        /* background-color:#666666b2;
        backdrop-filter: blur(10px); */
        /* box-shadow: 0 5px #ff0000; */
        width: 100%;
        height: 50px;
        /* border-radius: 7px; */
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        /* display: block;  */
    
    }
    .logo{
        display: none;
    }
    .nav-links-mobile{
        display: none;
    }
    .NavbarItems{

        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;  
        top: 50px;
    }

}
@media screen and (max-width:600px)  {
    .logonum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        padding:15px 20px;
        /* background-color:#666666b2;
        backdrop-filter: blur(10px); */
        /* box-shadow: 0 5px #ff0000; */
        width: 100%;
        height: 50px;
        /* border-radius: 7px; */
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        /* display: block;  */
    
    }
    .logo{
        display: none;
    }
    .nav-links-mobile{
        display: none;
    }
    .NavbarItems{

        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;  
        top: 50px;
        width: 100%;
        border-radius: 0px;
        padding: 0px 3px;
   
    }
    .nav-links{
        text-decoration: none;
      

        padding: 0.4rem 0.4rem;
        white-space: nowrap;
        font-size: 15px;
        margin: auto;
        
    }

}
@media screen and (max-width:420px) {
    .logonum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        padding:15px 20px;
        /* background-color:#666666b2;
        backdrop-filter: blur(10px); */
        /* box-shadow: 0 5px #ff0000; */
        width: 100%;
        height: 50px;
        /* border-radius: 7px; */
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        /* display: block;  */
    
    }
    .logo{
        display: none;
    }
    .nav-links-mobile{
        display: none;
    }
    .NavbarItems{
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        /* background-color: #007bff; */
        background-color: #f5f5f7c4;
        backdrop-filter: blur(10px);
    
        /* font-size: 0.5rem;
        padding: 0 0px;
        background-color: black;
        box-shadow: 0 5px red;
        width: 100%;
        height: 40px;
        border-radius: 13px;
        position: fixed;
        top: 20px; */
        /* left: 50%;
        transform: translate(-50%); */
        width: 100%;
        top: 50px;
       
    }
    .nav-links{
        text-decoration: none;
    
        padding: 0.4rem 0.2rem;
        white-space: nowrap;
        font-size: 15px;
        margin: auto;
        /* color: white; */
        
    }
    
 .nav-menu{
    display: flex;
    justify-content: center;
  
justify-content: space-between;
/* 
border: 2px solid red; */
/* font-size: 0.9rem;
padding-right:50px; */

}

   
    

}








@media screen and (max-width:425px) {
    .logonum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        padding:10px 10px;
        /* background-color:#666666b2;
        backdrop-filter: blur(10px); */
        /* box-shadow: 0 5px #ff0000; */
        width: 100%;
        height: 45px;
        /* border-radius: 7px; */
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        /* display: block;  */
        
    
    }
    .logonum img{
   
        width: 6.3rem;
        height: 2.4rem;
    
    }
    .mainlogo img{
        margin-top: 2px;
        width: 1.7rem;
        height: 2.5rem;
        margin-left: 10px;
    }


    .logo{
        display: none;
    }
    .nav-links-mobile{
        display: none;
    }
    .NavbarItems{
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        /* font-size: 0.5rem;
      
        background-color: black;
        box-shadow: 0 5px red;
        width: 100%;
        height: 40px;
        border-radius: 13px;
        position: fixed;
        top: 20px; */
        /* left: 50%;
        transform: translate(-50%); */
        /* margin: auto; */
        border-radius: 0px;
        width: 100%;
        /* background-color: red; */
        top: 42px;
        height: 45px;
       padding-left: 20px;
      
    }
    .nav-links{
        text-decoration: none;
       

        padding: 0.4rem 0.3rem;
        white-space: nowrap;
        font-size: 13px;
        margin: auto;
        color: #313131;
        
    }
 .nav-menu{
    display: flex;
    justify-content: center;
  
justify-content: space-between;
/* 
border: 2px solid red; */
/* font-size: 0.9rem;
padding-right:50px; */

}
.pno {
    font-weight: 600;
   
}

   .psbox span{
    font-size: 13px;
   }
    

}




