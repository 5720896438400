.Smain{
    text-align: center;
    
    padding-top: 70px;

       
       
   }

   #Spokenenglish img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Spokenenglish{
        background-color: #FFE5E5;
    }
    #Spokenenglish img{
        display: block;
       width: 100%;
       height: 100%;
    }
}

   @media screen and (max-width:620px){

   
    .Smain{
        
        padding-top: 80px;
    }
}