.Vmain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }

   #Svideoediting img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Svideoediting{
        background-color: #E5F2EC;
    }
    #Svideoediting img{
        display: block;
       width: 100%;
       height: 100%;
    }

}

   @media screen and (max-width:620px){

   
    .Vmain{
        
        padding-top: 80px;
    }
}