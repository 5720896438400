.demo{
    text-align: center;
    
    padding-top: 115px;


 
 
    /* background: rgb(214,214,214);
    background: linear-gradient(90deg, rgba(214,214,214,1) 0%, rgba(224,224,223,1) 35%, rgba(214,214,214,1) 100%);*/
    /* background: rgb(19,35,61);
background: linear-gradient(90deg, rgba(19,35,61,0.7035189075630253) 0%, rgba(31,43,19,0.5326505602240896) 25%, rgba(196,143,1,0.6194852941176471) 46%, rgba(227,128,96,0.7483368347338936) 72%, rgba(255,193,4,0.6895133053221288) 100%);
backdrop-filter: blur(10px); */
}
.maindemo{
    width: 95%;
    margin: auto;
    
    display: flex;
    justify-content: space-between;

    
    

}
.sdemo{
padding: 20px 10px;
/* background-color:#f4f4f4; */
background-color: #f2f2f2;
box-shadow: 0 27px 20px -15px rgba(8, 114, 163, 0.247);
border-radius: 7px;
margin-top :10px;

width: 32%;
height: 340px;
margin: 20px 0px 110px 0px;
/* height: 200px; */
/* flex-grow: 1; */


}
.stikybox{
    width: 50%;
    
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background: rgb(24,141,201);
background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
margin:-20px auto 0px auto;
padding: 8px 0px;
color: white;
font-weight:lighter;
}
.stikybox h3{
    color: white;
font-weight:400;
}
.demoform{
    width: 90%;
    height: 80%;
    border-radius: 7px ;
    border: 1px solid rgb(156, 156, 156);
    margin:20px auto;

}
@media screen and (max-width:620px){

   
    .maindemo{
        width: 95%;
        margin: auto;
        display: flex; 
        flex-wrap: wrap;
    }
    .sdemo{
        width: 100%;
        height:300px;
        margin-bottom: 5px;
    }
}