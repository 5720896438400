.Dmain{
    
    text-align: center;
    
    padding-top: 70px;

       
   }
   .dparentbox{
    background-color: white;
    width: 95%;
    /* height: 300px; */
    margin: auto;
    padding: 30px 10px;
    border-bottom: 1px solid rgb(223, 223, 223);
    box-shadow: 0px 2px 3px rgb(225, 225, 225);
   }
   .dbox{
    padding: 15px 10px;
   }
   .dbox h2{
    padding: 4px 10px;
   }
   .dbox img{
    width: 30%;
    height: 80px;
   }
   
   #Sdigitalmarketing img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Sdigitalmarketing{
        background-color: #F9DFEC;
    }
    #Sdigitalmarketing img{
        display: block;
       width: 100%;
       height: 100%;
    }
   }
   @media screen and (max-width:620px){

   

.dbox img{
    width: 100%;
    height: 100%;
}

   }

   @media screen and (max-width:620px){

   
    .Dmain{
        
        padding-top: 80px;
    }
}