.Gmain{
    
    text-align: center;
    
    padding-top: 70px;

       
   }

   #Sgraphicdesign img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Sgraphicdesign{
        background-color: #ECE5F2;
    }
    #Sgraphicdesign img{
        display: block;
       width: 100%;
       height: 100%;
    }
   }


   @media screen and (max-width:620px){

   
    .Gmain{
        
        padding-top: 80px;
    }
}