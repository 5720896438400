.Amain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }

   #Sautocad img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Sautocad{
        background-color: #AAAECE;
    }
    #Sautocad img{
        display: block;
       width: 100%;
       height: 100%;
    }
}

   @media screen and (max-width:620px){

   
    .Amain{
        
        padding-top: 80px;
    }
}