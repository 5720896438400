.Emain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }

.accdetails{
padding: 10px 0px 10px 70px;

   
   
}

.accdetails li{
    padding-top: 4px;
    /* width: 5px; */
    
    
}

/* .ewrap{
    display: inline;
} */
 
#SeAccounting img{
    display: none;
   }
   @media screen and (max-width:700px){
    #SeAccounting{
        background-color: #ffe5e5;
    }
    #SeAccounting img{
        display: block;
       width: 100%;
       height: 100%;
    }
   }

   @media screen and (max-width:620px){

   
        
    .Emain{
        
        padding-top: 80px;
    }
    .accdetails{
        padding: 10px 0px 10px 50px;
        
           
           
        }
}