.maincertificate{
    background: rgb(207,234,225);
background: linear-gradient(4deg, rgba(207,234,225,0.2945553221288515) 35%, rgba(253,245,198,0.44021358543417366) 82%);
    width: 100wh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.maincertificate a{
    width: 50%;
    padding: 10px;
    text-align: center;
    background-color: orange;
    background-image: linear-gradient(to right,#ff9234,#fc6274 100%);
    text-decoration: none;
    color: white;
    font-size: large;
    
}