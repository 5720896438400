.ADmain{
    
    text-align: center;
  
    padding-top: 70px;

       
       
   }


   @media screen and (max-width:620px){

   
    .ADmain{
        
        padding-top: 80px;
    }
}