.mhead{
    /* background-color:#ffffff; */
    background: rgb(24,141,201);
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
    backdrop-filter: blur(10px);
    box-shadow: 0.2px 2px 5px rgb(216, 216, 216);
    width: 95%;
    margin: auto;
    /* padding: 30px 0px; */
    padding: 5px 0px;
    /* color: rgb(98, 98, 98); */
    color: white;
    /* border-radius: 7px; */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    text-align: center;

}
.mainboxofhero{
   display: none;
}
.mainchildbox{
    display: none;
}
.mainchildbox1{
    display: none;
}
.btn{
    display: none;
}
.branch{
    display: none;
}
.branchheading{
    display: none;
}
@media screen and (max-width:620px){
    .mhead{
       
    
        /* backdrop-filter: blur(10px);
        box-shadow: 0.2px 2px 5px rgb(216, 216, 216);
       color: rgb(255, 255, 255);
        padding: 15px 8px;
    width: 100%;
    border-radius: 0px;  */
    background: #f5f5f7;
    background: linear-gradient(#f5f5f7,#f5f5f7);
    color: #6d6d72;
   
        box-shadow: none;
        padding: 15px 8px;
    width: 100%;
    border-radius: 0px; 
    text-align: left;


    }
    .ourbest{
        color: #1d1d1f;
       
    }
    .highlight{
        /* background-color:rgb(255, 255, 0); */
        /* padding: 2px 4px;
        border-radius: 5px; */
        /* background-color: #007aff; */
        /* color: white; */
        color: #1d1d1f;
        color: #007aff;
        font-weight: 800;
        
    }
   

}


@media screen and (max-width:450px){



.mainboxofhero{
    display: block;
    /* height: 725px; */
    height: 950px;
    width: 100%;
    
    /* background-color: #f2f2f2; */
background: rgb(207,234,225);
background: linear-gradient(4deg, rgba(207,234,225,0.2945553221288515) 35%, rgba(253,245,198,0.44021358543417366) 82%);
    backdrop-filter: 100px;
    margin:0px auto;
    padding: 110px 0px 0px 0px;
}

.mainchildbox1{
    text-align: left;
    display: block;
    width: 95%;
    padding:0px 8px 20px 8px;
    margin: auto;
    color: #6d6d72;
}
.mainchildbox1 h2{
    /* color: #423e3e !important;   */
    color: #6d6d72;

}
.mainchildbox{
    display: block;
    width: 95%;
   height: 250px;
    margin: auto;
    /* background-color: #f2f2f2; */
    box-shadow: 0 27px 20px -15px rgba(8, 114, 163, 0.247);
    border-radius: 10px;
}
.mainchildbox img{
    border-radius: 7px;
    width: 100%;
    height: 100%;
}
.mainchildbox1 article{
    padding-top: 8px;
    text-align: center;
    color: #1d1d1f;
    background-color:yellow;
    margin-top: 10px;
    padding: 8px;
    border-radius: 10px;
    /* box-shadow: 2px 2px 2px ; ; */
}
.loginform{
    width: 98%;
    height: 100px;
    margin:5px auto;
   
}
.loginform input:first-child{
    margin-top: 15px;
}
.loginform input{
    border: none;
    background-color:transparent;
    border-bottom: 1px solid   rgba(16,70,104,0.9136029411764706);
    width: 70%;
    height: 20px;
    margin: 14px;
    text-align: left;
    outline: none;


}
.loginform form{
    width: 100%;
    text-align: center;
    margin:30px auto;
    /* border: 1px solid red; */
    
}

.btn{
    display: inline-block;
    width: 34%;
    /* height: 30px; */
    padding: 6px 10px;
    /* height: 30px; */
    margin:7px 5px;
    border: none;
    /* background-image: linear-gradient(to right,#ff9234,#fc6274 100%); */
    background: rgb(24,141,201);
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
border-top-right-radius: 8px;
border-top-left-radius: 8px;
color: white;
box-shadow: 0 27px 20px -15px rgba(8, 114, 163, 0.247);

}

#certificate{
    background-color: orange;
    background-image: linear-gradient(to right,#ff9234,#fc6274 100%);
    width: 70%;
   
    
}
.btn a{
    color: white;
    text-decoration: none;
}
.branch{
    display: block;
    display: flex;
    justify-content: center;
}
.branch a{
    background: linear-gradient(90deg, rgba(255, 81, 46, 1) 0%, rgba(240, 126, 24, 1) 100%);
    color: white;
    text-align: center;
    text-decoration: none;
}
#chhattapurbutton{

}
.branchheading{
    display: block;
    text-align: center;
}

.loginform a{
  
    text-decoration: none;
    
}

}
