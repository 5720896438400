.Gmain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }

   #Googlesketchup img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Googlesketchup{
        background-color: #F2FCDF;
    }
    #Googlesketchup img{
        display: block;
       width: 100%;
       height: 100%;
    }
}
   @media screen and (max-width:620px){

   
    .Gmain{
        
        padding-top: 80px;
    }
}