.body{
    width: 100%;

    /* background: rgb(214,214,214);
    background: linear-gradient(90deg, rgba(214,214,214,1) 0%, rgba(224,224,223,1) 35%, rgba(214,214,214,1) 100%);*/
    /* background: rgb(19,35,61);
background: linear-gradient(90deg, rgba(19,35,61,0.7035189075630253) 0%, rgba(31,43,19,0.5326505602240896) 25%, rgba(196,143,1,0.6194852941176471) 46%, rgba(227,128,96,0.7483368347338936) 72%, rgba(255,193,4,0.6895133053221288) 100%); */
background: rgb(15,112,183);
background: linear-gradient(90deg, rgba(15,112,183,0.5438550420168067) 0%, rgba(255,255,255,0) 33%, rgba(255,255,255,0) 65%, rgba(15,112,183,0.5270483193277311) 100%);backdrop-filter: blur(10px);
} 
.Mainhome{
    background-color:#ffffff;
  
    box-shadow: 0.2px 2px 5px rgb(221, 221, 221);
    width: 95%;
    margin: auto;
    color: rgb(39, 39, 39);;
    border-radius: 7px;
    text-align: center;
}

.Mainhome h2{
    padding: 30px 10px 0px 10px;
}
p{
    display: flex;
    justify-content: center;
    padding: 10px 30px 30px 30px;
    font-weight: 500;
    text-align: center;

}
@media screen and (max-width:620px) {



    .Mainhome{
        background-color:#ffffff;
        backdrop-filter: blur(10px);
        /* box-shadow: 0.2px 2px 5px gray; */
        width: 100%;
        margin: auto;
        border-radius: 0px;
        text-align: center;
    }

    .home h1{
        font-size: 18px;

    }


}

@media screen and (max-width:400px) {

    .Mainhome{
        display: none;
    }
    
}