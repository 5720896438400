.Wmain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }
   #Swebdesing img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Swebdesing{
        background-color: #FFF2E5;
    }
    #Swebdesing img{
        display: block;
       width: 100%;
       height: 100%;
    }

}

   @media screen and (max-width:620px){

   
    .Wmain{
        
        padding-top: 80px;
    }
}