.Servicesmain{
    /* background-color:#ffffff; */
   background: rgb(24,141,201);
background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
    backdrop-filter: blur(10px);
    box-shadow: 0.2px 2px 5px rgb(227, 227, 227);
    width: 95%;
    margin:10px auto 0px auto;
    /* padding: 30px 0px; */
    padding: 5px 0px;
    color: rgb(251, 251, 251);
    /* border-radius: 7px; */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    text-align: center;
  


}
.childservicemain{
    background-color:#f4f4f4;
    width: 95%;
    /* height: 300px; */
    margin: auto;
    padding: 30px 0px;
  display: flex;
justify-content: space-between;
   
  
  border-bottom: 1px solid #e1e2e3;
  
  
   
}
.imginfo{
/* border: 2px solid red; */
padding: 20px;

width: 24%;
/* margin: auto; */


}
.imginfo h3{
padding-bottom: 3px;
}
/* .serviceimg{
    width: 250px;
    margin: auto;
   
    
} */
.imginfo img{
    width: 100%;
    /* height: 200px; */
    /* border-bottom: 1px solid rgb(227, 227, 227); */
 
}


/* @media screen and (max-width:1020px){

  .childservicemain{
    width: 100%;
    display: flex;
   
flex-wrap: wrap;
border-bottom: 1px solid #e1e2e3;
}

} */


@media screen and (max-width:620px){
    .Servicesmain{
     
        /* backdrop-filter: blur(10px);
        box-shadow: 0.2px 2px 5px rgb(216, 216, 216);
        margin:0px auto 0px auto;
        padding: 10px 5px;
    width: 100%;
    border-radius: 0px; */
    background: #f5f5f7;
    background: linear-gradient(#f5f5f7,#f5f5f7);
     box-shadow: none;
       color: #6d6d72;
       margin:0px auto 0px auto;
       padding: 10px 5px;
    width: 100%;
    border-radius: 0px; 
    text-align: left;
    


    }
    .ourservice{
        color: #1d1d1f;
    }

    .childservicemain{
        padding: 0px;
        width: 100%;
        display: flex;
    flex-wrap: wrap;
    border-bottom:none;
    background: #f5f5f7;
    background: linear-gradient(#f5f5f7,#f5f5f7);
    /* color: #6d6d72; */
    }
    .servicesheading{
        color: #007aff;
    }

    .imginfo{
        width: 100%;
        /* height:300px; */
    }
}

@media screen and (max-width:620px){
    .Servicesmain{
        box-shadow: none;
    }
    .serviceimg{
        display: flex;
        justify-content: center;
        align-items: center;
    }
   /*.childservicemain{
         background: rgb(255,146,52);
         background:linear-gradient(180deg, rgba(255, 147, 52, 0.284) 0%, rgba(252, 98, 116, 0) 12%, rgba(242,242,242,1) 30%); */
        /* background-image: linear-gradient(to right,#ff9234,#fc6274 100%); */
        /* box-shadow: 10px 2px 5px linear-gradient(to right,#ff9234,#fc6274 100%);
    } */

    .serviceimg img{
        width: 80%;
        height: 80%;
        
    }
}