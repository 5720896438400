.Pmain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }
   #Sprogramming img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Sprogramming{
        background-color: #E5F2F2;
    }
    #Sprogramming img{
        display: block;
       width: 100%;
       height: 100%;
    }
}

   @media screen and (max-width:620px){

   
    .Pmain{
        
        padding-top: 80px;
    }
}