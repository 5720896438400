.main_section{
    /* height: 400px; */
    width: 95%;
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(2,auto);
    /* background-color: red; */
    background-color:#f4f4f4;
    box-shadow: 0.2px 2px 5px rgb(216, 216, 216);
    
    /* backdrop-filter: blur(10px); */
    /* box-shadow: 0.2px 2px 5px gray; */
    border-radius: 7px;
    margin:10px auto;
    text-align: center;
    position: relative;

}

.mcse2
{

padding: 40px 50px;


}

.mcse{
    
    width: 100%;
    height: 100%;
    /* border: 2px solid black; */
}
.mcse2 h2{
    /* font-size: 2.5vw; */
    padding: 10px;
}


.mcse img{
    width: 100%;
    height: 100%;
    
    border-radius: 20px;
    padding: 10px;
   
}
/* .getaddmission{
    width: 100%;
    height: 200px;
    margin:10px auto 0px auto;
    border-radius: 7px ;
    background-color: red;
} */

@media screen and (max-width:700px)
{
    .main_section{
        display: flex;
        flex-wrap: wrap;
    }

}
@media screen and (max-width:620px){

    .mcse img{
        width: 100%;
        height: 100%;
        
        /* border-radius: 20px; */
        padding-top: 10px;
       
    }

.main_section{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin:0px auto;
    border-radius: 0px;
}
.mcse2
{

padding: 0px 20px 15px 20px;
}
.mcse h2{
   
    padding: 10px;
}
/* .mcse p{
    font-size: 18px;
} */



}

@media screen and (max-width:450px) {

    .main_section{
        display: none;
    }
    
}



