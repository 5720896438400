footer{
position: relative;
bottom: 0px;
  background: rgb(24,141,201);
  background: linear-gradient(180deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);

  padding:0px 10px 0px 10px;
}
.ffooter{
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 3fr));
   grid-column-gap: 6px;
   grid-row-gap:6px;
   /* border: 1px solid red; */
 
    /* height: 400px; */
    /* background-color: #ffffff; */
   
  backdrop-filter: blur(10px);
    text-align: left;
   color: white;
    /* color: rgb(76, 76, 76); */
    /* color: rgb(24, 24, 24); */
    padding: 40px 0px 20px 0px;
     
}
.ffooter h3{
  margin-bottom: -15px;
}
.Aboutbox{
  padding:10px;
  color: white;
  text-align: left;
}

.fbox{
    /* border: 2px solid red; */
    /* height: 200px; */

    width: 95%;
    /* margin: auto; */
    /* border: 1px solid red; */
   
 
}
.mobilesticky{
display: none;
}
@media screen and (max-width:450px){
  footer{
    padding-bottom: 34px;
  }
  .mobilesticky{
    width: 100%;
    /* height: 50px; */
    background-color: orange;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 11px 50px;
    font-size: x-large;
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
}


}
