/* @tailwind base;
@tailwind components;
@tailwind utilities; */
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html{
  /* scroll-behavior: smooth; */
 /* font-size: medium; */
 
  font-smooth: auto;
  color: rgb(34, 34, 34);
  /* overflow: scroll;
  overflow: inherit;
  -webkit-overflow-scrolling: scroll; */

  scroll-behavior: unset;
  
}

body {
  /* font-family:  Roboto; */
  /* background: rgb(24,25,25);
  background: linear-gradient(90deg, rgba(24,25,25,0.6615021008403361) 0%, rgba(75,47,20,0.6110819327731092) 33%, rgba(249,137,0,0.5046393557422969) 65%, rgba(56,59,61,0.6474964985994398) 100%); */

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif  ; */
    /* font-family: 'Tilt Neon', cursive; */
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Urbanist', sans-serif !important; */
    /* font-weight: 300; */

    font-family: 'Nunito Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 
      background-color: #fff;
  width: 100%;
   height: 100vh;

   overflow: auto;
  scrollbar-width: none;
  


}
h2{
  /* font-weight: 600; */
/* font-size: small; */
/* font-size: medium; */

}
h3{
  font-weight: bolder;
  font-size: medium;
  /* color: red; */
}

body::-webkit-scrollbar{
display: none;

}

/* @font-face {
  font-family:Roboto;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn1.byjus.com/byjusweb/fonts/roboto-woff-fonts/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
} */

/* @font-face{
 font-family:Roboto;
  src: url(../public/Roboto-Regular.ttf) format(ttf);
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  
  
} */




@media screen and (max-width:620px){
  html{
    font-size: 14px;
  }
  h2{
    font-size: 20px;

  }
}


/* @media screen and (max-width:1000px) {
  body{
  width: 50%;
  margin: auto;
  }
} */
@media screen and (max-width:620px){

  body{
    font-family: 'Nunito Sans', sans-serif !important;
  }

}