.CDmain{
    
    text-align: center;
    
    padding-top: 70px;

    
    
}



#Scomputerdiploma img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Scomputerdiploma{
        background-color: #eefdd3;
    }
    #Scomputerdiploma img{
        display: block;
       width: 100%;
       height: 100%;
    }
   }

@media screen and (max-width:620px){

   
    .CDmain{
        
        padding-top: 80px;
    }
}