.Threemain{
    
    text-align: center;
       padding-top: 70px;
       
       
   }

   #threeds img{
    display: none;
   }
   @media screen and (max-width:700px){
    #threeds{
        background-color: #F9DFEC;
    }
    #threeds img{
        display: block;
       width: 100%;
       height: 100%;
    }
}

   @media screen and (max-width:620px){

   
    .Threemain{
        
        padding-top: 80px;
    }
}