.contact{
    text-align: center;
    
    padding-top: 100px;
/* background-color: orange; */
    
    /* background: rgb(214,214,214);
    background: linear-gradient(90deg, rgba(214,214,214,1) 0%, rgba(224,224,223,1) 35%, rgba(214,214,214,1) 100%);*/
    /* background: rgb(19,35,61);
background: linear-gradient(90deg, rgba(19,35,61,0.7035189075630253) 0%, rgba(31,43,19,0.5326505602240896) 25%, rgba(196,143,1,0.6194852941176471) 46%, rgba(227,128,96,0.7483368347338936) 72%, rgba(255,193,4,0.6895133053221288) 100%);
backdrop-filter: blur(10px); */
}
.maincontact{
    width: 95%;
    margin: 10px  auto 100px auto;

    
    display: flex;
    justify-content: space-between;

    
    

}
.scontact{
padding: 20px 10px;
background-color: #f2f2f2;
box-shadow: 0 27px 20px -15px rgba(8, 114, 163, 0.247);
border-radius: 7px;
margin:10px 0px 20px 0px;

width: 100%;
height: 400px;
margin: auto;
/* height: 200px; */
/* flex-grow: 1; */


}
.stikybox{
    width: 50%;
    
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background: rgb(24,141,201);
background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
margin:-20px auto 0px auto;
padding: 8px 5px;
color: white;

z-index: 999;
/* font-weight:lighter; */
}
.stikybox h3{
    color: white;
/* font-weight:lighter; */
}
form{
    width: 400px;
    height: 300px;
   
    text-align: center;
    margin:40px auto;
    
}
form input{
    border: none;
    background-color:#f4f4f4;
    border-bottom: 1px solid   rgba(16,70,104,0.9136029411764706);
    width: 70%;
    height: 20px;
    margin: 20px;
    text-align: left;
    /* font-weight: lighter; */
    

    outline: none;
    /* border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove; */
    
    
}
.no-outline:focus {
    outline: none;
  }
form button{
    width: 40%;
    height:30px;
    border: none;
    color: white;
    background: rgb(24,141,201);
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
@media screen and (max-width:620px){

    .maincontact{
        width: 95%;
        margin: 10px  auto ;
    }
    .contact{
        text-align: center;
        
        padding-top: 110px;
    
        
        /* background: rgb(214,214,214);
        background: linear-gradient(90deg, rgba(214,214,214,1) 0%, rgba(224,224,223,1) 35%, rgba(214,214,214,1) 100%);*/
        /* background: rgb(19,35,61);
    background: linear-gradient(90deg, rgba(19,35,61,0.7035189075630253) 0%, rgba(31,43,19,0.5326505602240896) 25%, rgba(196,143,1,0.6194852941176471) 46%, rgba(227,128,96,0.7483368347338936) 72%, rgba(255,193,4,0.6895133053221288) 100%);
    backdrop-filter: blur(10px); */
    }
    form{
        width: 100%;
        height: 300px;
       
        text-align: center;
        margin:30px auto;
        
    }
    .scontact{
        margin-bottom: 20px;
    }
}