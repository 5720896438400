.googlemain{
    background: rgb(24,141,201);
    background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%);
        backdrop-filter: blur(10px);
        box-shadow: 0.2px 2px 5px rgb(227, 227, 227);
        width: 95%;
        margin:10px auto 0px auto;
        /* padding: 30px 0px; */
        padding: 5px 0px;
        color: rgb(251, 251, 251);
        /* border-radius: 7px; */
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        text-align: center;
      
    
    
    }


#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
 
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
   
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    /* margin-left: auto;
    margin-right: auto; */
    width: 95%;
    margin: auto;
    
    
  }
  .Rmain{
    /* width: 200px;
    height: 200px; */
    /* background-color:#f4f4f4; */
    background-color: #f2f2f2;
    padding: 40px 70px;
  
 
  }

 
    .Rbox{
        width:100%;
       height: 280px;
        background-color: #f2f2f2;
        box-shadow: 0 27px 20px -15px rgba(8, 114, 163, 0.247);
        border-radius: 7px;
        padding: 15px;
        margin: auto;
        
    }

    .reviewcontant{
    padding: 5px 0px 0px 0px;
     
    }
    .Rbox h3{
        padding-bottom: 10px;
    }

.rimg{
    width: 120px;
    height: 120px;
    margin: auto;
    padding: 0px 0px 10px 0px;
  
   
}
.rimg img{
    width: 100%;
    height: 100%;
}

#firstreview{
  background-color: #f9dfec !important; 
}
#secondreview{
  background-color: #f2f9e5 !important; 
}
#thirdreview{
  background-color: #ffe5e5 !important; 
}
#forthreview{
  background-color: #f2ecec !important; 
}
#fifthreview{
  background-color: #e5f2f9 !important; 
}
#sixthreview{
  background-color: #ece5f2 !important; 
}
#seventhreview{
  background-color: #e5f2ec !important; 
}
#eighthreview{
  background-color: #f9f9e5 !important; 
}

#ninthreview{
  background-color: #fff2e5 !important; 
}
#tenthreview{
  background-color: #e5f2f2 !important; 
}

    @media screen and (max-width:620px){
        .googlemain{
         
            /* backdrop-filter: blur(10px);
            box-shadow: 0.2px 2px 5px rgb(216, 216, 216);
            margin:0px auto 0px auto;
            padding: 10px 5px;
        width: 100%;
        border-radius: 0px; */

        background: #f5f5f7;
        background: linear-gradient(#f5f5f7,#f5f5f7);
       
        color: #6d6d72;
            box-shadow: none;
           margin:0px auto 0px auto;
            padding: 12px 8px;
        width: 100%;
        border-radius: 0px; 
        text-align: left;
    
   
        }
        .ourstudent{
          color: #1d1d1f;
      }


        .Rmain{
            padding: 0px;
            width: 100%;
            padding: 35px 0px;
            display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #e1e2e3;
        background: #f5f5f7;
       

    }
    .swiper{
      width: 100%;
      margin: auto;
    }

    .Rbox{
      width: 95%;
     height: 210px;
     
  }
}


@media screen and (max-width:450px){

}