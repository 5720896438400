.Edmain{
    
    text-align: center;
    
    padding-top: 70px;

       
       
   }


   #Sadvancedexcelimg img{
    display: none;
   }
   @media screen and (max-width:700px){
    #Sadvancedexcelimg{
        background-color: #BACCEC;
    }
    #Sadvancedexcelimg img{
        display: block;
       width: 100%;
       height: 100%;
    }
   }
   @media screen and (max-width:620px){

   
    .Edmain{
        
        padding-top: 80px;
    }
}