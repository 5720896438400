.stickycontact{
    
    padding: 10px 8px;
    /* height: 22%; */
    /* background-color: red; */
    position: fixed;
    top: 40%;
    right: 0px;
    z-index: 100;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: orange;
    /* background: linear-gradient(266deg, rgba(24,141,201,1) 0%, rgba(16,70,104,0.9136029411764706) 100%); */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.stickycontact i{
    width: 20px;
    height:20px;
    /* background-color: red; */
    font-size: large;
    color: white;
    display: block;
    padding: 0px 0px;
    margin: auto;
    cursor: pointer;
    /* border: 1px solid blue; */
   
}
/* .stickycontact{
    display: none;
} */
@media screen and (max-width:450px){

 
    .stickycontact{
     display: none;
    }
   
}