.DCmain{
    
    text-align: center;
    
    padding-top: 70px;
    

       
       
   }


   @media screen and (max-width:620px){

   
    .DCmain{
        
        padding-top: 80px;
    }
   }